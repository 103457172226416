const categorySelectionPageUrl = '/categories';
const brandSelectionPageUrl = '/brands';

const fieldsForRelated = ['category_full'];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

function getLocalPreselection(pageType, defaults) {
  const isCategoryPage = pageType === 'category';
  const isBrandPage = pageType === 'brand';

  if (isCategoryPage || isBrandPage) {
    const breadcrumbs = Array.from(
      window.document.querySelectorAll('.breadcrumbs .breadcrumb, .breadcrumbs .breadcrumb-text'),
    )
      .map((el) => el.textContent.replace('chevron_right', '').replace('-', '').trim())
      .join('>');

    if (breadcrumbs !== 'Home' && breadcrumbs.startsWith('Home')) {
      const trimmedTermFromBreadcrumbs = breadcrumbs.replace('Home>', '');
      return [
        {
          field: isCategoryPage ? 'category_full' : 'brand_name',
          term: trimmedTermFromBreadcrumbs,
          treeLevel: isCategoryPage ? trimmedTermFromBreadcrumbs.split('>').length - 1 : null,
        },
      ];
    }
  }

  return defaults.getter(pageType);
}

export default {
  platform: 'bigcommerce',
  getLocalPreselection,
  page: {
    getPageType: (defaults) =>
      window.location.pathname.includes(categorySelectionPageUrl) ||
      window.location.pathname.includes(brandSelectionPageUrl)
        ? 'parts'
        : defaults.getter(),
  },
  SearchRequestDefaults: {
    pageSize: 36,
    sort: window.Convermax.config?.defaultSort ?? 'relevance',
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.page-category .page.plp-page',
      template: 'SearchPage',
      visibleIf: () => !window.location.pathname.includes(categorySelectionPageUrl),
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: {
        replace: 'header .search-container.header-desktop',
        class: 'search-container header-desktop cm_search-box-root__container-desktop',
      },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'MobileSearchBox',
      type: 'SearchBoxDialogButton',
      location: {
        selector: 'header .search-container.header-mobile',
        class: 'cm_search-box-root__container-mobile',
      },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: {
        selector: 'body.page-category .page.plp-page',
        class: 'cm_vehicle-categories__category',
      },
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
      visibleIf: () => window.location.pathname.includes(categorySelectionPageUrl),
    },
    {
      name: 'RelatedItems',
      location: 'body.page-product #tab-related',
      template: 'product/relatedItems',
      count: 6,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
